import { Grid, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Order } from '../../../global';
import { getOrderById, updateOrder } from '../../../setup/api/orders';
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import SelectField from '../../../ui/components/FieldSelect/FieldSelectWithoutFormix';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { TitleHeading } from "../../../ui/components/Titles/Title";

export const OrderDetailsPage: FC = () => {
    const [order, setOrder] = useState<Order>();

    const { id } = useParams<{ id: string }>();

    const [requestError, setRequestError] = useState<string>(``);

    const update = useFetchWithAlert(alertPayload(`Order status updated`));

    const { request } = useFetch();
    const history = useHistory();

    const updateStatus = (status: string) => {
        const payload = {
            status
        };

        update.request(updateOrder, id, payload)
            .then(res => {
                if (!res.id) {
                    setRequestError(res);
                }
            })
            .catch(error => {
                setRequestError(error);
                console.log(error);
            });
    };

    const handleSubmit = () => {
        console.log(`no submit`);
    };

    useEffect(() => {
        request(getOrderById, id)
            .then(res => {
                console.log(res);
                setOrder(res);
            })
            .catch(error => {
                console.log(error);
            });
    }, [id]);

    if (!order) {
        return (
            <p>Loading</p>
        );
    }

    return <ModalCompar
        open={true}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={order}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {(props: FormikProps<Partial<Order>>) => (
                <Form
                    onChange={() => {
                        setRequestError(``);
                    }}
                >
                    <Grid container justifyContent={`space-between`} padding={1}>
                        <TagFormGrid item xs={12}>
                            <TitleHeading children="Order information" style={{
                                fontSize: `1.2rem`,
                                paddingBottom: `1em`
                            }} />
                            <Grid xs={4}>
                                <SelectField
                                    name='status'
                                    label='Status'
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#FFF`,
                                        border: `none`,
                                    }}
                                    value={props.values.status ? { id: props.values.status, value: props.values.status } : null}
                                    onChange={value => {
                                        if (value) {
                                            props.setFieldValue(`status`, value.value);
                                            updateStatus(value.value);
                                        }
                                    }}
                                    options={[
                                        { id: `In Progress`, value: `In Progress` },
                                        { id: `Shipped`, value: `Shipped` },
                                        { id: `Delivered`, value: `Delivered` },
                                        { id: `Cancelled`, value: `Cancelled` },
                                        { id: `Returned`, value: `Returned` },
                                        { id: `Failed`, value: `Failed` },
                                    ]}
                                    getOptionLabel={option => option.value}
                                    width={`100%`}
                                />
                            </Grid>
                            <TitleHeading children="Delivery information" style={{
                                fontSize: `1rem`,
                                paddingBottom: `1em`
                            }} />
                            <Grid container justifyContent={`space-between`} alignItems={`center`}
                                style={{
                                    backgroundColor: `white`,
                                    padding: `1em`,
                                    borderRadius: `1em`
                                }}>
                                <Grid xs={2}>
                                    <TitleHeading children="Delivery Name" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.deliveryName} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Delivery Address" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.deliveryAddress} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Delivery Postal" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.deliveryPostal} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Delivery City" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.deliveryCity} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Delivery Country" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.deliveryCountry} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container justifyContent={`space-between`} alignItems={`center`}
                                style={{
                                    backgroundColor: `white`,
                                    padding: `1em`,
                                    borderRadius: `1em`
                                }}>
                                <Grid xs={2}>
                                    <TitleHeading children="Total Amount" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={`${props.values.totalAmount} ${props.values.currency}`} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Total Local Amount" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={`${props.values.totalLocalAmount} ${props.values.localCurrency}`} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Shipping Provider" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.shippingName} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Shipping Time" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={props.values.shippingDelay} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Shipping Cost" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={`${props.values.shippingCost} ${props.values.localCurrency}`} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                                <Grid xs={2}>
                                    <TitleHeading children="Payment Fee" style={{
                                        fontSize: `0.5rem`,
                                    }} />
                                    <TitleHeading children={`${props.values.paymentFee} ${props.values.localCurrency}`} style={{
                                        fontSize: `0.75rem`,
                                    }} />
                                </Grid>
                            </Grid>
                            <br />
                            <TitleHeading children="Order Details" style={{
                                fontSize: `1rem`,
                                paddingBottom: `1em`
                            }} />
                            <Grid
                                container
                                alignItems="center"
                                style={{
                                    backgroundColor: `white`,
                                    padding: `1em`,
                                    borderRadius: `1em`
                                }}
                            >
                                {Array.isArray(props.values.orderDetails) &&
                                    props.values.orderDetails.map((orderDetail, index) => (
                                        <Grid
                                            key={orderDetail.product?.id ?? index}
                                            xs={4}
                                        >
                                            <TitleHeading children={`${orderDetail.product.brand} - ${orderDetail.product.name} - ${orderDetail.product.amount}`} style={{
                                                fontSize: `0.5rem`,
                                            }} />
                                            <img
                                                src={`${orderDetail.product?.imageUrl}`}
                                                alt="Product"
                                                className="image"
                                                width={`50%`}
                                            />
                                            <TitleHeading children={`Amount: ${orderDetail.quantity}`} style={{
                                                fontSize: `1rem`,
                                            }} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
    </ModalCompar>;
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));